import React, { } from 'react'
import { Container } from 'react-bootstrap'
import Image from 'next/image'

interface IProps {
  firstTitle?: string
  secondTitle?: string
  description?: string
  imageUrl: string
}

const HeroBanner = ({ firstTitle, secondTitle, description, imageUrl }: IProps) => {
  // const isDescriptionFilled = description && description.length > 0
  return (
    <>
      <div className="hero-banner">
        <Image
          layout="fill"
          objectFit="cover"
          alt="Banner"
          placeholder='blur'
          src={require(`../../../public${imageUrl}`)}
          style={{
            zIndex: -2,
            pointerEvents: 'none',
            objectFit: 'cover'
          }}
          priority
        />
        <div className="hero-banner_content">
          <Container>
            <div className="hero-banner_content_inner">
              {firstTitle && <h1 className="hero-banner_content_header text-center">
                <span className="hero-banner_content_header-terms">{firstTitle}</span> {secondTitle}</h1>}
              { description && <h5 className="align-self-center font-oxanium text-center"> {description} </h5> }
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default HeroBanner
