import React from 'react'

import CopyRight from '@/components/footer/CopyRight'
import Footer from '@/components/footer/Footer'

import SystemBar from '@/components/nav/SystemBar'
import TopNavBar from '@/components/nav/TopNavBar'
import { ToastContainer } from 'react-toastify'

interface IGeneralLayoutProps {
  children: React.ReactNode;
}

const GeneralLayout = ({ children } : IGeneralLayoutProps) => (
  <>
    <SystemBar />
    <TopNavBar />
    <ToastContainer/>
    <div className="general-layout">
      {children}
    </div>
    <Footer />
    <CopyRight />
  </>
)

export default GeneralLayout
